import { Pipe, PipeTransform } from '@angular/core'

import { ELanguage } from '~/core/models'
import { compactNumber } from '~/utils/index'

@Pipe({
  name: 'compactNumber',
  standalone: true,
})
export class CompactNumber implements PipeTransform {
  transform(num: number, lang: ELanguage, emptyString = '', isCurrency = true): string {
    return compactNumber(num, lang, emptyString, isCurrency)
  }
}
